<template>
  <div class="app-introduce">
    <div class="app-logo">
           <img :src="require('@/assets/image/logo/单图形烫金.png')" :preview="false" style="width: 1.7rem; height: 1.7rem" alt=""/>
    </div>
    <div class="app-text">
      <h3>应用名称</h3>
      <p>辰汇S</p>
      <h3>应用简介</h3>
      <p>深圳市前海辰宇投资咨询有限公司企业内部ERP系统APP,提供了业绩看板、产品库、资讯、通知管理等功能</p>
      <h3>使用前必读</h3>
      <p>1.iOS商店下载更新无需兑换码,首次安装使用原链接安装,可在APP内直接更新</p>
      <p>2.iOS港区上架,不再需要强制切换内地Apple ID</p>
      <p>3.iOS更新后会变为独立APP,原APP手动卸载</p>
      <p>4.Android应用内更新</p>
      <p>5.电脑端打开此网页无效，请使用手机下载。</p>
      <h3>开发者</h3>
      <p>深圳市前海辰宇投资咨询有限公司</p>
      <a-button class="downloadButton" @click="downloadClick" type="primary" shape="round" size="large">
        <template #icon>
          <DownloadOutlined class="icon"/>
          下载APP
        </template>
      </a-button>
    </div>

  </div>
</template>

<script>
import { DownloadOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Introduce',
  components: {
    DownloadOutlined
  },
  methods: {
    downloadClick () {
      if (this.isWeiXin()) {
        alert('请点击右上角↗️使用浏览器打开')
        return
      }
      if (this.isAndroid()) {
        window.location.href = 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/front/app/version/app-release.apk'
        alert('安装包开始下载')
      } else if (this.isIOS()) {
        window.location.href = 'https://apps.apple.com/cn/app/%E8%BE%B0%E6%B1%87-%E6%96%B0/id6456394112'
      } else {
        alert('请使用手机下载安装')
      }
    },
    isAndroid () {
      const u = navigator.userAgent
      return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    },
    isIOS () {
      const u = navigator.userAgent
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    },
    isWeiXin () {
      const ua = window.navigator.userAgent.toLowerCase()
      const o = ua.match(/MicroMessenger/i)
      if (o && o.toString() === 'micromessenger') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>¡

<style scoped>
.app-introduce {
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  text-align: center;
}
.app-logo {
  display: block;
  margin: 0 auto;
  line-height: 1rem;
}
.app-text{
  margin-top: 1rem;
  float: left;
  text-align: left;
}

.downloadButton{
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 15%;
  width: 70%;
  justify-content: center;
}

.icon{
  margin-top: 0.08rem;
  margin-right: 0.2rem;
}

</style>
